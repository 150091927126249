import { Routes } from '@angular/router'
import { FullComponent } from './layouts/full/full.component'
import { AuthGuard } from './auth/auth.guard'
import { AltUserGuard } from './auth/altUser.guard'

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/auth',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule'
      },
      {
        path: 'account',
        loadChildren: './account/account.module#AccountModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'merchants',
        loadChildren: './merchants/merchants.module#MerchantModule',
        canActivate: [AuthGuard, AltUserGuard]
      },
      {
        path: 'helpdesk',
        loadChildren: './helpdesk/helpdesk.module#HelpdeskModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: './settings/settings.module#SettingsModule',
        canActivate: [AuthGuard]
      }
    ]
  }
]
