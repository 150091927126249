import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { AuthService } from '../services/auth.service'
import { MatSnackBar } from '@angular/material'

@Injectable()
export class AltUserGuard implements CanActivate {
    constructor(private authService: AuthService, private snackBar: MatSnackBar) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let isNotAltUser = true
        const user = this.authService.getUser()
        if ( state.url.indexOf('account') >=0 || state.url.indexOf('extensions') >=0 || state.url.indexOf('employees') >=0 ) {
            if (user.__t === 'Merchant') {
                isNotAltUser = true
            } else if (user.__t === 'Altuser') {
                isNotAltUser = false
             }
        }
        if(!isNotAltUser) {
            this.snackBar.open(
                "Your type account don't have access to use this feature.", 'Close', { duration: 5000 }
            )
        }
        return isNotAltUser
    }
}
