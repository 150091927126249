import { Component, OnInit, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { AuthService } from '../../services/auth.service'
import { InvoiceService } from '../../services/invoices.service'

@Component({
  selector: 'app-save-payment-method',
  templateUrl: './save-payment-method.component.html',
  styleUrls: ['./save-payment-method.component.css']
})
export class SavePaymentMethodComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private invoiceService: InvoiceService,
    public dialogRef: MatDialogRef<SavePaymentMethodComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close()
  }

  changePaymentSaved(event) {
    if (event.checked) {
      this.authService.setPaymentSaved(true)
    } else {
      this.authService.setPaymentSaved(false)
    }
  }

  goToPayment() {
    this.invoiceService.getSpecificInvoice(this.data.invoices._id)
  }
}
