import { Component, OnInit, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { InvoiceService } from '../../services/invoices.service'
import { RequestService } from '../../services/request.service'
import { AuthService } from '../../services/auth.service'
import { User } from '../../model/user.model'
@Component({
  selector: 'app-payment-info-modal',
  templateUrl: './payment-info-modal.component.html',
  styleUrls: ['./payment-info-modal.component.css']
})
export class PaymentInfoModalComponent implements OnInit {
  private changePayment: boolean
  private allExt: any
  public user: User
  constructor(
    private invoiceService: InvoiceService,
    private request: RequestService,
    public dialogRef: MatDialogRef<PaymentInfoModalComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.changePayment = false
  }

  ngOnInit() {
    this.user = this.authService.getUser()
  }

  onNoClick(): void {
    this.dialogRef.close()
  }
  confirm() {
    if (this.data.invoices && this.data.invoices._id) {
      this.invoiceService.getSpecificInvoice(this.data.invoices._id)
    } else {
      this.request
        .postResource('me/subscriptions/' + this.data.period, { extensions: this.data.extensions })
        .then(extension => {
          this.allExt = extension
          let invoiceId = ''
          this.allExt.map(subscription => {
            if (subscription.type === this.data.period) {
              invoiceId = subscription.invoices.pop()
              this.invoiceService.getSpecificInvoice(invoiceId)
            }
          })
        })
    }
  }

  changePaymentSaved(event) {
    if (event.checked) {
      this.authService.setPaymentSaved(true)
    } else {
      this.authService.setPaymentSaved(false)
    }
  }

  changeCurringType(event) {
    if (event.checked) {
      this.data.payment_details.recurring_type = 'first'
      this.invoiceService.setCurringType(this.data.payment_details)
    } else {
      this.data.payment_details.recurring_type = 'recurring'
      this.invoiceService.setCurringType(this.data.payment_details)
    }
  }
}
