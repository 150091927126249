import { Component, OnInit, Input, Inject } from '@angular/core'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material'
import { BusinessInformationModalComponent } from './business-information-modal/business-information-modal.component'
import moment from 'moment'
import { SelectSubscriptionModalComponent } from './select-subscription-modal/select-subscription-modal.component'
import { GoToPaymentComponent } from './go-to-payment/go-to-payment.component'
import { InvoiceService } from '../../../services/invoices.service'
import { RequestService } from '../../../services/request.service'
import { User } from '../../../model/user.model'
import { Invoice } from '../../../model/invoice.model'

@Component({
  selector: 'app-go-live',
  templateUrl: './go-live.component.html',
  styleUrls: ['./go-live.component.css']
})
export class GoLiveComponent implements OnInit {
  @Input()
  getUser: User
  private invoices: Invoice[]
  private gettingLive: boolean
  private user: any
  private expiresAt: string

  constructor(
    public dialogRef: MatDialogRef<GoLiveComponent>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private invoiceService: InvoiceService,
    private request: RequestService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.gettingLive = false
  }

  ngOnInit() {
    // this.invoices = this.invoiceService.getInvoices()
    this.user = this.data.user
    this.expiresAt = moment(this.data.expiresAt).fromNow()
  }

  openDialog(): void {
    window.analytics && window.analytics.track('resellerdashboard:go-live:buton')
    this.invoices = this.invoiceService.getInvoices()
    if (this.user) {
      if (this.invoices.length || this.gettingLive) {
        if (!this.invoices.length) {
          this.invoiceService.fetchInvoices()
          setTimeout(() => {
            this.invoices = this.invoiceService.getInvoices()
            const dialogRef = this.dialog.open(GoToPaymentComponent, {
              width: '70%',
              data: { invoices: this.invoices }
            })
          }, 2800)
        } else {
          const dialogRef = this.dialog.open(GoToPaymentComponent, {
            width: '70%',
            data: { invoices: this.invoices }
          })
        }
      } else {
        const dialogRef = this.dialog.open(BusinessInformationModalComponent, {
          width: '70%',
          data: { user: this.user }
        })
      }
    }
  }
  clodeDialog() {
    this.dialogRef.close(false)
  }
}
