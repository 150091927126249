import { Component, OnInit, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'

@Component({
  selector: 'resource-dialog',
  templateUrl: 'resource-dialog.component.html'
})
export class ResourceDialogComponent implements OnInit {
  private title: string
  private text: string
  private showInput: boolean

  constructor(
    public dialogRef: MatDialogRef<ResourceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.showInput = false
  }

  ngOnInit() {
    switch (this.data.type) {
      case 'delete':
        this.title = 'Delete item'
        this.text = 'want_delete_item'
        break
      case 'delete_selected':
        this.title = 'Delete all selected items'
        this.text = 'want_delete_all_items'
        break
      case 'duplicate':
        this.title = 'Duplicate item'
        this.text = 'want_duplicate_item'
        this.showInput = true
        break
        case 'merchant_live':
          this.title = 'Set merchant live'
          this.text = 'Set merchant live'  
          break
      default:
        this.title = ''
        this.text = ''
        break
    }
  }

  cancel(): void {
    this.dialogRef.close(false)
  }

  confirm(): void {
    if (this.data.name) {
      this.dialogRef.close(this.data.name)
    } else {
      this.dialogRef.close(true)
    }
  }
}
