// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  version: '1.0.0',
  production: true,
  local: false,
  client_id: 'client-OBWOtAOBPJvCgJMQfLBzVL3j2iZ3I0ngeb9A9ekT',
  client_secret: 'secret-MzibxJjkGF6iS52xxz0V0E3tW1xKixZtrIjcFPmO',
  grant_type: 'password',
  api_server: 'https://api.countr.rest/v2/',
  app_server: 'https://applications.countrhq.com',
  SegmentSourceKey: 'gc3tkjvp44',
}
