import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { AuthService } from './auth.service'
import { environment } from '../../environments/environment'
import { Merchant, NewMerchant } from '../model/merchant.model'
import { User } from '../model/user.model'

@Injectable()
export class MerchantsService {
  private merchants: Merchant[]
  private newMerchant: NewMerchant
  private count: number
  private user: User

  constructor(private request: RequestService, private authService: AuthService) { }

  async getMerchant(id: string) {
    let user = this.authService.getUser()
    return user.merchants
  }

  setMerchant(merchant: Merchant): Promise<Merchant> {
    let index = this.merchants.findIndex(merch => merch._id === merchant._id)
    if (index >= 0) {
      return this.request
        .patchResource('merchants/' + merchant._id, merchant)
        .then((updated: Merchant) => {
          this.merchants[index] = updated
          return Promise.resolve(updated)
        })
    } else {
      return this.request.postResource('merchants', merchant).then((updated: Merchant) => {
        this.merchants = [...[updated], ...this.merchants]
        return Promise.resolve(updated)
      })
    }
  }


  async postNewMerchant(body) {
    const rawResponse = await fetch(`${environment.api_server}merchants`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('countr-dashboard:access_token')}`
      },
      body: JSON.stringify({
        username: body.email,
        password: body.password,
        confirmation: body.confirmation,
        email: body.email,
        billing_info: body.billing_info,
        contact: body.contact,
        extras: body.extras,
        signup_source: body.signup_source
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

}
