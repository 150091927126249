import { AuthService } from './auth.service'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable()
export class RequestService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getResource(resource: string, params?: any) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.getToken())
    headers.set('Content-Type', 'application/json')

    return this.http.get(`${environment.api_server}${resource}`, { headers, params }).toPromise()
  }

  postResource(resource: string, body?: Object) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.getToken())
    headers.set('Content-Type', 'application/json')

    return this.http.post(`${environment.api_server}${resource}`, body, { headers }).toPromise()
  }

  patchResource(resource: string, body?: Object) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.getToken())
    headers.set('Content-Type', 'application/json')

    return this.http.patch(`${environment.api_server}${resource}`, body, { headers }).toPromise()
  }

  putResource(resource: string, body?: Object) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.getToken())
    headers.set('Content-Type', 'application/json')

    return this.http.put(`${environment.api_server}${resource}`, body, { headers }).toPromise()
  }

  deleteResource(resource: string, params?: any) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.getToken())
    headers.set('Content-Type', 'application/json')

    return this.http.delete(`${environment.api_server}${resource}`, { headers, params }).toPromise()
  }

  searchResouce(resource: string, params?: any) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.getToken())
    headers.set('Content-Type', 'application/json')

    return this.http
      .get(`${environment.api_server}${resource}/search`, { headers, params })
      .toPromise()
  }
}
