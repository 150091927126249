import { Injectable, Output, EventEmitter } from '@angular/core'

@Injectable()
export class DataService {
  private theme: string
  private searchTerm: string = ''

  @Output()
  private changeTheme = new EventEmitter<string>()

  constructor() {
    this.theme = 'light'
  }

  initDataService() {
    this.checkTheme()
  }

  checkTheme() {
    let t = localStorage.getItem('countr-dashboard:theme')

    if (t !== null) {
      this.theme = t
    }
  }

  getTheme() {
    return this.theme
  }

  setTheme() {
    this.theme = this.theme === 'light' ? 'dark' : 'light'
    localStorage.setItem('countr-dashboard:theme', this.theme)
    this.changeTheme.emit(this.theme)
  }

  getChangeTheme() {
    return this.changeTheme
  }

  getSearchTerm(): string {
    return this.searchTerm
  }

  setSearchTerm(term: string) {
    this.searchTerm = term
  }
}
