import { Component, OnInit, Inject } from '@angular/core'
import { EUCOUNTRIES } from './../eu-countries'
import { AuthService } from '../../../../services/auth.service'
import { SelectSubscriptionModalComponent } from '../select-subscription-modal/select-subscription-modal.component'
import { GoLiveSalesModalComponent } from '../go-live-sales-modal/go-live-sales-modal.component'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import * as jsvat from 'jsvat'

export interface DialogData {
  animal: string
  name: string
}

@Component({
  selector: 'business-information-modal',
  templateUrl: './business-information-modal.component.html',
  styleUrls: ['./business-information-modal.component.css']
})
export class BusinessInformationModalComponent implements OnInit {
  private euCountries = EUCOUNTRIES
  private isEu: boolean
  private country: string
  private showErrorMessage: boolean

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<BusinessInformationModalComponent>,
    public dialogRefSubs: MatDialogRef<SelectSubscriptionModalComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {}
  onNoClick(): void {
    this.dialogRef.close()
  }

  checkCountry(event) {
    this.isEu = false
    if (event.isUserInput) {
      setTimeout(() => {
        this.euCountries.map(eu => {
          if (event.source.viewValue === eu.name) {
            this.isEu = true
          }
        })
        this.country = event.source.value
      }, 200)
    }
  }

  saving(user) {
    this.showErrorMessage = false
    let info = user.billing_info
    if (info.organization && info.registration && info.address1 && info.city && this.country) {
      this.dialogRef.close()
      user.billing_info.country = this.country
      this.authService.updateBillingInfo(user)
      this.openSubs()
    } else {
      this.showErrorMessage = true
    }
  }

  checkVAT(vat: string) {
    if (vat && vat.length) {
      const result = jsvat.checkVAT(vat)
      return result.isValid
    }
    return false
  }

  openSubs(): void {
    const dialogRef = this.dialog.open(GoLiveSalesModalComponent, {
      width: '60%',
      // data: { country: this.country }
    })
  }
}
