import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Subject, Subscription, BehaviorSubject } from 'rxjs';

export class EventService{

    private childClickedEvent = new BehaviorSubject<any>('');

     emitChildEvent(msg: any){
        this.childClickedEvent.next(msg)
     }
     childEventListner(){
        return this.childClickedEvent.asObservable();
      }
   }