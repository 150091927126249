import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { RequestService } from '../../services/request.service'
import { Merchant } from '../../model/merchant.model'
@Component({
  selector: 'app-employee-selection',
  templateUrl: './employee-selection.component.html',
  styleUrls: ['./employee-selection.component.css']
})
export class EmployeeSelectionComponent implements OnInit {
  private employees: Merchant[]
  private selectedEmployee: Merchant
  @Output()
  sendSelectedEmployee = new EventEmitter<{ employee: Merchant; index: number }>()
  constructor(private request: RequestService) {
    this.employees = []
  }

  ngOnInit() {
    this.getEmployees()
  }

  getEmployees() {
    this.request.getResource('employees').then((employees: Merchant[]) => {
      this.employees = employees
      // this.selectedEmployee = employees[0]
    })
  }

  changeSelectedEmployee(event) {
    if (event.isUserInput) {
      if (event.source.viewValue === 'All Employees') {
        this.selectedEmployee = null
        this.sendSelectedEmployee.emit({ employee: null, index: null })
      } else {
        this.selectedEmployee = event.source.value
        let index = this.employees.findIndex(s => s._id === this.selectedEmployee._id)
        this.sendSelectedEmployee.emit({ employee: this.selectedEmployee, index: index })
      }
    }
  }
}
