import { Injectable, OnInit } from '@angular/core'

export interface BadgeItem {
  type: string
  value: string
}
export interface Saperator {
  name: string
  type?: string
}
export interface ChildrenItems {
  state: string
  name: string
  type?: string
}

export interface Menu {
  state: string
  name: string
  type: string
  icon: string
  badge?: BadgeItem[]
  saperator?: Saperator[]
  children?: ChildrenItems[]
}

const MENUITEMS = [
  {
    state: 'account',
    name: 'account',
    type: 'sub',
    icon: 'account_box',
    // badge: [{ type: 'red', value: '2' }],
    children: [
      { state: 'profile', name: 'profile' },
      { state: 'invoices', name: 'invoices' },
    ]
  },
  {
    state: 'merchants',
    name: 'my_merchants',
    type: 'link',
    icon: 'people'
  },
  {
    state: 'helpdesk',
    name: 'helpdesk',
    type: 'link',
    icon: 'info'
  },
  {
    state: 'old-dashboard',
    name: 'old-dashboard',
    type: 'link',
    icon: 'dvr'
  }
]

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS
  }
}
