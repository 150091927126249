import { Component, OnInit, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { RequestService } from '../../../../services/request.service'

@Component({
    selector: 'go-live-sales-modal',
    templateUrl: './go-live-sales-modal.component.html'
  })

  export class GoLiveSalesModalComponent {
    public isRequested: boolean
    constructor(
        public dialogRef: MatDialogRef<GoLiveSalesModalComponent>,
        private request: RequestService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
      this.isRequested = false 
    }

    proceed(): void {
      this.request.postResource('me/requestlive').then(res => {
        this.isRequested = true
      })
    }
    onNoClick(): void {
      this.dialogRef.close()
    }
  }
