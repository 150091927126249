import { Component, OnInit, Inject } from '@angular/core'
import { SubscriptionService } from '../../../../services/subscriptions.service'
import { Subscription } from '../../../../model/subscription.model'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { InvoiceService } from '../../../../services/invoices.service'

@Component({
  selector: 'app-go-to-payment',
  templateUrl: './go-to-payment.component.html',
  styleUrls: ['./go-to-payment.component.css']
})
export class GoToPaymentComponent implements OnInit {
  private subscriptions: Subscription[]
  private invoice: string
  public extensions: any[]
  public selectedPackage: any[]
  public total_price: string

  constructor(
    private invoiceService: InvoiceService,
    private subscriptionService: SubscriptionService,
    public dialogRef: MatDialogRef<GoToPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.subscriptions = this.subscriptionService.getSubscriptions()
    this.extensions = []
    this.selectedPackage = []
    this.total_price = ''
  }

  ngOnInit() {
    if (this.data.invoices && !this.invoice) {
      // this.selectedPackage = this.data.package
      this.subscriptionService.fetchSubscriptions()
      setTimeout(() => {
        this.subscriptions = this.subscriptionService.getSubscriptions()
        this.subscriptions.map(subscription => {
          if (subscription.type === 'system') {
            this.invoice = subscription.invoices[subscription.invoices.length - 1].toString()
          }
        })
      }, 1000)
    }
    if (this.data.invoices) {
      this.total_price = this.data.invoices[0].subtotal
      this.data.invoices[0].extensions.map(extension => {
        if (
          extension.slug === 'live-advanced' ||
          extension.slug === 'live-pro' ||
          extension.slug === 'live-basic' ||
          extension.slug === 'live-basic-woocommerce' ||
          extension.slug === 'live-pro-woocommerce' ||
          extension.slug === 'live-advanced-transaction'
        ) {
          this.selectedPackage.push(extension)
        } else {
          this.extensions.push(extension)
        }
      })
    }
    if(this.data.package) {
      this.selectedPackage.push(this.data.package)
      this.extensions = this.data.extensions
    }
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  pay() {
    if (this.data && this.data.invoice) {
      this.invoiceService.getSpecificInvoice(this.data.invoice)
    } else {
      this.invoiceService.getSpecificInvoice(this.invoice)
    }
  }
}
