import * as $ from 'jquery'
import { MediaMatcher } from '@angular/cdk/layout'
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { MenuItems } from '../../shared/menu-items/menu-items'
import { AuthService } from './../../services/auth.service'
import { DataService } from '../../services/data.service'
import { User } from './../../model/user.model'
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router'

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.component.scss']
})
export class FullComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList

  private _mobileQueryListener: () => void
  private showMenu: boolean
  private userSubscription: any
  private darkThemeSubscription: any
  private darkTheme: boolean
  private getUser: User
  private userPos: string
  private url: string
  private getNavigation: string

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService,
    private dataService: DataService,
    private router: Router
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)')
    this._mobileQueryListener = () => changeDetectorRef.detectChanges()
    this.mobileQuery.addListener(this._mobileQueryListener)
    this.showMenu = false
    this.url = 'https://countrhq.com/'
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.getNavigation = event.url
      }
    })
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.getUserEvent().subscribe((user: User) => {
      if (user && user.username) {
        const replaceDot = user.username.split('.').join('-')
        this.userPos = replaceDot.split('@')[0]
      }
      this.getUser = user
      this.showMenu = user ? true : false
      this.url = user ? 'index.html' : 'https://countrhq.com/'
    })
    this.darkThemeSubscription = this.dataService.getChangeTheme().subscribe((theme: string) => {
      this.darkTheme = theme === 'dark' ? true : false
    })

    this.darkTheme = this.dataService.getTheme() === 'dark' ? true : false
    this.getNavigation = this.router.url
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener)
  }

  showSearch() {
    ;(<any>$('#app-search')).toggle(200)
  }
}
