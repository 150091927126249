import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import 'hammerjs'
import { Location } from '@angular/common'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import 'rxjs/Rx'

declare global {
  interface Window {
    analytics: any
  }
}

if (environment.production) {
  enableProdMode()
  if (location.protocol === 'http:') {
    window.location.href = location.href.replace('http', 'https')
  }
}

// describe browser support
// - check localStorage functionality
if (window.hasOwnProperty('localStorage'))
  try {
    window.localStorage.browseSupprot = 2
  } catch (e) {
    document.body.classList.add('private-mode')
    throw new Error("Countr can't work on Private mode.")
  }

// If the Segment library has been successfully included, start tracking things
if (window.analytics) {
  window.analytics.load(environment.SegmentSourceKey)
  // Now we can trigger events, such as: mywindow.analytics.page()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err))
