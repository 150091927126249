import { COMMA, ENTER } from '@angular/cdk/keycodes'
import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { MatChipInputEvent } from '@angular/material/chips'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

@Component({
  selector: 'auto-complete-multiple-filter',
  templateUrl: 'auto-complete-multiple-filter.component.html'
})
export class AutoCompleteMultipleFilter implements OnInit {
  visible = true
  selectable = true
  removable = true
  addOnBlur = true
  separatorKeysCodes: number[] = [ ENTER, COMMA ]
  optionsCtrl = new FormControl()
  filteredOptions: Observable<string[]>
  optionsSelected: any[] = []
  @Input() options: any[]
  @Input() key: string
  @Input() title: string
  @Input() disable: boolean
  @Output() sendingResult = new EventEmitter()
  @Input() initOptions: any[]

  @ViewChild('optionInput', { read: false })
  optionInput: ElementRef<HTMLInputElement>
  @ViewChild('auto', { read: false })
  matAutocomplete: MatAutocomplete

  constructor() {
    this.filteredOptions = this.optionsCtrl.valueChanges.pipe(
      startWith(null),
      map(op => (op ? this._filter(op) : this.options.slice()))
    )
  }

  ngOnInit(): void {
    if (this.initOptions && this.initOptions.length) {
      this.optionsSelected = this.initOptions
    }

    if (this.disable) {
      this.optionsCtrl.disable()
      this.options = []
    }
  }

  add(event: MatChipInputEvent): void {
    // if event.value has length, it means that no valid options was found
    // so dont need to create a chip for this one
    if (event.value.length) {
      return
    }
    // Add option only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input
      const value = event.value

      if ((value || '').trim()) {
        this.optionsSelected.push(value.trim())
      }

      // Reset the input value
      if (input) {
        input.value = ''
      }

      this.optionsCtrl.setValue(null)
    }
  }

  remove(op: any) {
    const index = this.optionsSelected.indexOf(op)

    if (index >= 0) {
      this.optionsSelected.splice(index, 1)
    }

    this.sendingResult.emit(this.optionsSelected)
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const i = this.optionsSelected.findIndex(
      item => item[this.key] === event.option.value[this.key]
    )

    if (i < 0) {
      this.optionsSelected.push(event.option.value)
      this.optionInput.nativeElement.value = ''
      this.optionsCtrl.setValue(null)
      this.sendingResult.emit(this.optionsSelected)
    }
  }

  private _filter(value: any) {
    const filterValue =
      typeof value === 'string' ? value.toLowerCase() : value[this.key].toLowerCase()

    return this.options.filter(op => op[this.key].toLowerCase().includes(filterValue))
  }
}
