import { NgModule } from '@angular/core'

import { MaterialModule } from '../material-module'
import { CommonModule } from '@angular/common'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ChartsModule } from 'ng2-charts'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MenuItems } from './menu-items/menu-items'
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion'
import { EmployeeSelectionComponent } from '../common-components/employee-selection/employee-selection.component'
import { ResourceTableComponent } from '../common-components/resource-table/resource-table.component'
import { ResourceDialogComponent } from '../common-components/resource-table/resource-dialog.component'
import { GoLiveComponent } from '../common-components/resource-table/go-live/go-live.component'
import { PaymentInfoModalComponent } from '../common-components/payment-info-modal/payment-info-modal.component'
import { SavePaymentMethodComponent } from '../common-components/save-payment-method/save-payment-method.component'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { TranslatePipe } from '../pipes/translate.pipe'
import { JoinPipe } from '../pipes/join.pipe'
import { OrderByName } from '../pipes/orderby.pipe'
import { AutoCompleteFilter } from '../common-components/auto-complete-filter/auto-complete-filter.component'
import { AutoCompleteMultipleFilter } from './../common-components/auto-complete-filter/auto-complete-multiple-filter.component'
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core'
// import { ReplacePipe } from '../pipes/replace.pipe'

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    NgxMaterialTimepickerModule,
    FlexLayoutModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    TranslatePipe,
    JoinPipe,
    OrderByName,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    EmployeeSelectionComponent,
    ResourceTableComponent,
    ResourceDialogComponent,
    GoLiveComponent,
    PaymentInfoModalComponent,
    SavePaymentMethodComponent,
    AutoCompleteFilter,
    AutoCompleteMultipleFilter
  ],
  exports: [
    TranslatePipe,
    JoinPipe,
    OrderByName,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    EmployeeSelectionComponent,
    ResourceTableComponent,
    ResourceDialogComponent,
    GoLiveComponent,
    PaymentInfoModalComponent,
    SavePaymentMethodComponent,
    AutoCompleteFilter,
    AutoCompleteMultipleFilter
  ],
  providers: [
    MenuItems,
  ],
  entryComponents: [
    ResourceDialogComponent,
    GoLiveComponent,
    PaymentInfoModalComponent,
    SavePaymentMethodComponent,
  ]
})
export class SharedModule { }
