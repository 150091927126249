import { Injectable } from '@angular/core'
import CountrClient from '@countr/sdk'
import { environment } from '../../environments/environment'

@Injectable()
export class CountrSDKService {
  private instance: CountrClient

  constructor() {}

  initCountrSdk(): CountrClient {
    const countrClient = new CountrClient({
      opts: {
        staging: !environment.production ? true : false,
        local: !environment.production && environment.local ? true : false,
        enableSocket: true
      }
    })

    countrClient.setClient({
      client_id: environment.client_id,
      client_secret: environment.client_secret
    })

    countrClient.on('refreshed', async token => {
      try {
        localStorage.setItem('countr-dashboard:access_token', token.access_token)
        localStorage.setItem('countr-dashboard:refresh_token', token.refresh_token)
      } catch (error) {
        console.error(error)
      }
    })

    return countrClient
  }

  getCountrSdk() {
    if (!this.instance) {
      this.instance = this.initCountrSdk()
    }

    return this.instance
  }
}
