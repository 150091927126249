import { InvoiceService } from './../../../services/invoices.service'
import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../../services/auth.service'
import { Notification, NotificationType, NotificationIcon } from '../../../model/notification.model'
import { MatDialog } from '@angular/material'
import { SelectSubscriptionModalComponent } from '../../../common-components/resource-table/go-live/select-subscription-modal/select-subscription-modal.component'
import { GoLiveSalesModalComponent } from '../../../common-components/resource-table/go-live/go-live-sales-modal/go-live-sales-modal.component'
import { GoToPaymentComponent } from '../../../common-components/resource-table/go-live/go-to-payment/go-to-payment.component'
import { BusinessInformationModalComponent } from '../../../common-components/resource-table/go-live/business-information-modal/business-information-modal.component'
import * as moment from 'moment'
import { RequestService } from '../../../services/request.service'


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./../full.component.scss']
})
export class AppHeaderComponent implements OnInit {
  private notifications: Notification[] = []
  private trialSubscription: any
  private gettingLive: boolean
  private trialDays: string
  private user: any
  public checkTypeUser: any


  constructor(
    private authService: AuthService,
    private invoiceService: InvoiceService,
    private dialog: MatDialog,
    private request: RequestService

  ) {}

  ngOnInit() {
    this.checkTypeUser = this.authService.getUser().__t
    this.trialSubscription = this.authService.getTrialEvent().subscribe(async trial => {
      if (trial) {
        const trialExpires = this.authService.getTrialExpireDate()
        // const diff = trialExpires.getDate() - new Date().getDate()
        // moment(Date.parse(store.state.user.trial_expires_at)).fromNow()
        const diff = moment(trialExpires).fromNow()
        this.trialDays = diff
        const notObjTrial: Notification = {
          round: NotificationType.INFO,
          icon: NotificationIcon.USER,
          title: 'Go Live',
          subject: 'trial_expires',
          action: () => {
            this.openDialog()
          }
          // time: '10:00 AM'
        }
        this.notifications.push(notObjTrial)
      }
    })
  }

  async openDialog() {
    let invoices = this.invoiceService.getInvoices()
    await this.request.getResource('me').then(users => {
      if(users) {
        return this.user = users
      }
    })
    if (
      this.user && this.user.billing_info && this.user.billing_info.registration &&
      !invoices.length &&
      !this.gettingLive
    ) {
      const dialogRef = this.dialog.open(GoLiveSalesModalComponent, {
        width: '60%'
      })
      // dialogRef.afterClosed().subscribe(result => {
      //   this.gettingLive = result.getPackage
      // })
    } else if (invoices.length || this.gettingLive) {
      if (!invoices.length) {
        await this.invoiceService.fetchInvoices()
        invoices = this.invoiceService.getInvoices()
        const dialogRef = this.dialog.open(GoToPaymentComponent, {
          width: '70%',
          data: { invoices: invoices }
        })
      } else {
        const dialogRef = this.dialog.open(GoToPaymentComponent, {
          width: '70%',
          data: { invoices: invoices }
        })
      }
    } else {
      const dialogRef = this.dialog.open(BusinessInformationModalComponent, {
        width: '70%',
        data: { user: this.user }
      })
    }
  }
}
