import * as $ from 'jquery'
import 'angular2-navigate-with-data'
import { BrowserModule } from '@angular/platform-browser'
import { NgModule, APP_INITIALIZER } from '@angular/core'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { LocationStrategy, HashLocationStrategy } from '@angular/common'

import { AppRoutes } from './app.routing'

import { AppComponent } from './app.component'

import { FlexLayoutModule } from '@angular/flex-layout'
import { FullComponent } from './layouts/full/full.component'
import { AppHeaderComponent } from './layouts/full/header/header.component'
import { PopUpBox } from './layouts/full/pop-up-box/pop-up-box.component'
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MaterialModule } from './material-module'
import { AuthGuard } from './auth/auth.guard'
import { AltUserGuard } from './auth/altUser.guard'
import { MatFormFieldModule, MatInputModule } from '@angular/material'
// import { RecaptchaModule } from 'ng-recaptcha'

import { SharedModule } from './shared/shared.module'
import { SpinnerComponent } from './shared/spinner.component'
import { AuthService } from './services/auth.service'
import { RequestService } from './services/request.service'
import { DataService } from './services/data.service'
import { SettingsService } from './services/settings.service'
import { UtilsService } from './services/utils.service'
import { TimeZoneService } from './services/time-zone.service'
import { InvoiceService } from './services/invoices.service'
import { EventService } from './services/event.service'
import { TranslateService } from './services/translate.service'
import { MetadataService } from './services/metadata.service'
import { MerchantsService } from './services/merchants.service'
// import { TimeZoneService } from './services/time-zone.service'
import { CountrSDKService } from './services/countr-sdk.service'
import { BusinessInformationModalComponent } from './common-components/resource-table/go-live/business-information-modal/business-information-modal.component'
import { SelectSubscriptionModalComponent } from './common-components/resource-table/go-live/select-subscription-modal/select-subscription-modal.component'
import { GoLiveSalesModalComponent } from './common-components/resource-table/go-live/go-live-sales-modal/go-live-sales-modal.component'
import { SubscriptionService } from './services/subscriptions.service'
import { GoToPaymentComponent } from './common-components/resource-table/go-live/go-to-payment/go-to-payment.component'
// import { TranslatePipe } from './translate.pipe'
// import { PaymentInfoModalComponent } from './common-components/payment-info-modal/payment-info-modal.component'
// import { CompareReportsComponent } from './common-components/compare-reports/compare-reports.component';
// import { DialogCreateReportComponent } from './common-components/compare-reports/dialog-create-report/dialog-create-report.component';

// import { StoreModule } from '@ngrx/store'
// import { reducer } from './store/reducer'

export function setupTranslateFactory(service: TranslateService): Function {
  return () => service.use('en')
}

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    PopUpBox,
    SpinnerComponent,
    AppSidebarComponent,
    BusinessInformationModalComponent,
    SelectSubscriptionModalComponent,
    GoLiveSalesModalComponent,
    GoToPaymentComponent
    // TranslatePipe
    // PaymentInfoModalComponent
    // StockHistoryModalComponent
    // BottomProductsSellersComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    // RecaptchaModule,
    RouterModule.forRoot(AppRoutes)
    // StoreModule.forRoot({ categories: reducer })
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AuthService,
    AuthGuard,
    AltUserGuard,
    RequestService,
    DataService,
    SettingsService,
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    UtilsService,
    MerchantsService,
    TimeZoneService,
    InvoiceService,
    SubscriptionService,
    EventService,
    MetadataService,
    CountrSDKService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    BusinessInformationModalComponent,
    SelectSubscriptionModalComponent,
    GoLiveSalesModalComponent,
    GoToPaymentComponent
  ]
})
export class AppModule {}
