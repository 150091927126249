import { Injectable, Output, EventEmitter } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { RequestService } from './request.service'
import { environment } from '../../environments/environment'
import { Token } from './../model/token.model'
import { User, BillingInfo } from '../model/user.model'
import UAParser from 'ua-parser-js'

@Injectable()
export class AuthService {
  private auth: boolean
  private user: User
  @Output() private userIsLoggedIn = new EventEmitter<User>()
  @Output() private isTrial = new EventEmitter<boolean>()

  constructor(private http: HttpClient) {
    this.auth = false
  }

  hasToken() {
    return localStorage.getItem('countr-dashboard:access_token') !== null
  }

  getToken() {
    return localStorage.getItem('countr-dashboard:access_token')
  }

  setToken(token: string) {
    localStorage.setItem('countr-dashboard:access_token', token)
  }

  getRefreshToken() {
    return localStorage.getItem('countr-dashboard:refresh_token')
  }

  setRefreshToken(token: string) {
    localStorage.setItem('countr-dashboard:refresh_token', token)
  }

  isAuth() {
    return this.auth
  }

  getUser(): User {
    return { ...this.user }
  }

  isAltUser(): boolean {
    return this.user.__t === 'Altuser'
  }

  getUserEvent() {
    return this.userIsLoggedIn
  }

  getTrialEvent() {
    return this.isTrial
  }

  userName(user): string {
    const contact = user.contact || {}
    return `${contact.first_name || ''} ${contact.middle_name || ''} ${contact.last_name ||
      ''}`.trim()
  }

  getTrialExpireDate(): Date {
    const user = this.getUser()
    return new Date(user.trial_expires_at)
  }

  addMerchant(merchant) {
    this.user.merchants.push(merchant)
    return { ...this.user }
  }

  userTraits() {
    const user = this.getUser()
    const uaParser = new UAParser(navigator.userAgent).getResult()
    return {
      _id: user._id,
      __t: user.__t,
      email: user.email,
      username: user.username,
      name: this.userName(user),
      signed_up: user.created_at,
      last_seen: user.last_login,
      ecommerce_type: user.integration || 'none',
      signup_source: user.signup_source,
      // merchant_id: user.merchant_id,
      language: navigator.language,
      // phone: (user.contact || {}).phone || '',
      trial_expires_at: user.trial_expires_at,
      // organization: (user.billing_info || {}).organization || '',
      // altuser: user.altuser,
      // ...window.__analytics__ua__ || {}
      ...uaParser
    }
  }

  updateBillingInfo(billing: BillingInfo) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getToken())
    headers.set('Content-Type', 'application/json')

    return this.http.patch(`${environment.api_server}me`, billing, { headers }).toPromise().then(
      (bill: BillingInfo) => {
        return Promise.resolve(true)
      },
      error => {
        console.log(error)
        // this.auth = false
        return Promise.reject(false)
      }
    )
  }

  setPaymentSaved(type) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getToken())
    headers.set('Content-Type', 'application/json')

    return this.http
      .patch(`${environment.api_server}me`, { 'billing_info.payment_saved': type }, { headers })
      .toPromise()
      .then(
        (bill: BillingInfo) => {
          return Promise.resolve(true)
        },
        error => {
          console.log(error)
          // this.auth = false
          return Promise.reject(false)
        }
      )
  }

  checkLogin() {
    if (this.hasToken()) {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getToken())
      headers.set('Content-Type', 'application/json')

      return this.http.get(`${environment.api_server}me`, { headers }).toPromise().then(
        (user: User) => {
          this.auth = true
          this.user = user
          this.userIsLoggedIn.emit(user)

          const trial = user.trial_expires_at && user.trial_expires_at !== null
          this.isTrial.emit(trial)
          if (user && user.__t === 'Reseller') {
            if (user.extras && user.extras.support_message) {
              window.analytics &&
                window.analytics.identify(user._id, this.userTraits(), {
                  integrations: { All: false, Mixpanel: true, Intercom: false }
                })
            } else {
              window.analytics &&
                window.analytics.identify(user._id, this.userTraits(), {
                  integrations: { All: false, Mixpanel: true, Intercom: true }
                })
            }
          } else {
            this.auth = false
            this.userIsLoggedIn.emit(null)

          }
          return Promise.resolve(true)
        },
        error => {
          console.log(error)
          this.auth = false
          return Promise.reject(false)
        }
      )
    } else {
      this.auth = false
      return Promise.reject({
        status: 'no_token',
        message: 'Auto login failed! Please try to login with your credentials.'
      })
    }
  }

  login(username: string, password: string): any {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    const body = `username=${username}&password=${password}&client_id=${environment.client_id}&client_secret=${environment.client_secret}&grant_type=${environment.grant_type}`
    return this.http
      .post(`${environment.api_server}oauth/token`, body, { headers })
      .toPromise()
      .then(
        (token: Token) => {
          this.auth = true
          this.setToken(token.access_token)
          this.setRefreshToken(token.refresh_token)
          const h = new HttpHeaders().set('Authorization', 'Bearer ' + token.access_token)
          h.set('Content-Type', 'application/json')
          // adding user to the service
          return this.http
            .get(`${environment.api_server}me`, { headers: h })
            .toPromise()
            .then((user: User) => {
              this.user = user
              this.userIsLoggedIn.emit(user)
              const trial = user.trial_expires_at && user.trial_expires_at !== null

              // Event was not send without the timeout
              setTimeout(() => {
                this.isTrial.emit(trial)
              }, 1000)
              if (user && user.__t === 'Reseller') {
                window.analytics &&
                  window.analytics.identify(user._id, this.userTraits(), {
                    integrations: { All: false, Mixpanel: true, Intercom: true }
                  })
              } else {
                this.auth = false
                this.userIsLoggedIn.emit(null)
              }
              return Promise.resolve(token)
            })
        },
        error => {
          this.auth = false
          return Promise.reject(error)
        }
      )
  }

  signUp(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    headers.set('Authorization', 'Bearer ')
    const credentials = {
      username: body.email,
      password: body.password,
      signup_source: 'dashboard'
    }

    return this.http
      .post(`${environment.api_server}merchants`, { body }, { headers })
      .toPromise()
      .then(test => {
        console.log(test)
      })
  }

  signout() {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('countr-dashboard:access_token')
      localStorage.removeItem('countr-dashboard:refresh_token')
      this.auth = false
      this.user = undefined
      this.userIsLoggedIn.emit(undefined)
      window.analytics && window.analytics.reset()
      return resolve(true)
    })
  }
}
