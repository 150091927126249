import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import {
  MatTableDataSource,
  MatPaginator,
  PageEvent,
  MatSort,
  MatDialog,
  MatSnackBar
} from '@angular/material'
import { RequestService } from './../../services/request.service'
import { ResourceDialogComponent } from './resource-dialog.component'
import { GoLiveComponent } from './go-live/go-live.component'
import { PaymentInfoModalComponent } from '../payment-info-modal/payment-info-modal.component'
import { SavePaymentMethodComponent } from '../save-payment-method/save-payment-method.component'
import { AuthService } from '../../services/auth.service'
import * as moment from 'moment'
import { AngularCsv } from 'angular7-csv'

@Component({
  selector: 'resource-table',
  templateUrl: 'resource-table.component.html',
  styleUrls: ['resource-table.component.scss']
})
export class ResourceTableComponent implements OnInit, OnChanges {
  public errorImg = 'assets/images/countr_icon.svg'

  //  Input properties
  @Input()
  type: string
  @Input()
  columns: string[]
  @Input()
  actions: string[]
  @Input()
  shouldResourceRefresh: boolean
  // Output events
  @Output()
  sendClickItem = new EventEmitter<string>()

  private selection = []

  private resource: any
  private resourceCount: number
  private skip: number
  private isLoading: boolean
  private pdf: any
  private user: any
  private canDelete: boolean
  private downloadData: any[]
  private customersCount: number
  private limitFlag: number
  private flag: number
  // Mat table properties
  private dataSource: MatTableDataSource<any>
  @ViewChild(MatPaginator)
  paginator: MatPaginator
  @ViewChild(MatSort)
  sort: MatSort

  constructor(
    private request: RequestService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private authService: AuthService,
  ) {
    this.initResourceTable()
    this.user = authService.getUser()
    this.canDelete = true
    this.downloadData = []
    this.limitFlag = 0
    this.flag = 0
  }

  ngOnInit(): void {
    this.getResouceCount()
    this.getResouce()
  }

  ngOnChanges(): void {
    if (this.shouldResourceRefresh) {
      this.initResourceTable()
      this.getResouceCount()
      this.getResouce()
    }
  }

  initResourceTable() {
    this.resource = []
    this.resourceCount = 0
    this.skip = 0
    this.isLoading = true
  }

  getResouceCount() {
    if (this.type === 'merchants') {
      this.actions = [`merchant_live`]
      this.resourceCount = this.user.merchants.length
    } else {
      this.request
        .getResource(this.type === 'invoices' ? 'me/invoices' : this.type + '/count')
        .then((count: number) => {
          this.resourceCount = count
        })
    }
  }

  getResouce() {
    if (this.type === 'merchants') {
      this.resource = this.fixDates(this.user.merchants)
      this.dataSource = new MatTableDataSource<any>(this.resource)
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort
      this.isLoading = false

    } else {
      const opts = {
        limit: 100,
        sort: '-updated_at',
        skip: this.skip
      }

      this.request
        .getResource(this.type === 'invoices' ? 'me/invoices' : this.type, opts)
        .then((resource: any) => {
          if (this.type === 'products') {
            resource = this.productsTemplate(resource)
          } else if (this.type === 'devices') {
            resource = this.devicesTemplate(resource)
          }

          resource = this.fixDates(resource)
          this.resource = [...this.resource, ...resource]
          this.dataSource = new MatTableDataSource<any>(this.resource)
          this.dataSource.paginator = this.paginator
          this.dataSource.sort = this.sort
          this.isLoading = false
        })
    }
  }

  onPageChange(event: PageEvent) {
    if (this.resource.length < this.resourceCount) {
      this.skip += 10
      this.getResouce()
    }
  }

  applyFilter(event: any) {
    const filterValue = event.target.value
    this.dataSource.filter = filterValue.trim().toLowerCase()

    if (!this.dataSource.filteredData.length && this.type !== `merchants`) {
      const opts = {
        limit: 10,
        sort: '-updated_at',
        text: filterValue
      }
      this.request.searchResouce(this.type, opts).then((resource: any) => {
        if (this.type === 'products' && resource.length) {
          resource = this.productsTemplate(resource)
        }

        resource = this.fixDates(resource)
        this.resource = [...this.resource, ...resource]
        this.dataSource = new MatTableDataSource<any>(this.resource)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
        this.dataSource.filter = filterValue.trim().toLowerCase()
      })
    } else if (!this.dataSource.filteredData.length && this.type === `merchants`) {
      let querry =
        this.resource = this.user.merchants.filter(res =>
          res.contact.first_name.trim().toLowerCase().includes(filterValue) ||
          res.contact.last_name.trim().toLowerCase().includes(filterValue) ||
          res.email.trim().toLowerCase().includes(filterValue) ||
          res.contact.phone.trim().toLowerCase().includes(filterValue))
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort
      this.dataSource.filter = filterValue.trim().toLowerCase()
      this.dataSource = new MatTableDataSource<any>(this.resource)
    } else if (this.type === `merchants` && !filterValue.length && this.resource.length !== this.user.merchants.length) {
      this.getResouce()
    }
  }

  fixDates(resource) {
    resource.forEach(res => {
      res.created_at = new Date(res.created_at).toLocaleDateString()
      res.updated_at = new Date(res.updated_at).toLocaleDateString()
      res.trial_expires_at = res.trial_expires_at ? new Date(res.trial_expires_at).toLocaleDateString() : null
      res.live = res.live ? new Date(res.live).toLocaleDateString() : null
      if (this.type === 'invoices') {
        res.invoice_date = new Date(res.invoice_date).toLocaleDateString()
        if (res.payments.length && res.payments[0].date) {
          res.payments[0].date = new Date(res.payments[0].date).toLocaleDateString()
          res.pay = res.payments[0].date
        }
      }
    })

    return resource
  }

  productsTemplate(products) {
    products.forEach(product => {
      product.categories_string = product.categories.map(category => category.name).join(', ')
      product.tax_string = product.tax.name
    })

    return products
  }

  devicesTemplate(devices) {
    devices.forEach(device => {
    })

    return devices
  }

  deleteResource(id: string) {
    if (this.type === 'stores') {
      this.request.deleteResource(this.type + '/' + id)
      setTimeout(() => {
        this.snackBar.open(this.type + ' deleted.', 'Close', {
          duration: 5000
        })
        this.initResourceTable()
        this.getResouceCount()
        this.getResouce()
      }, 2000)
    } else {
      this.request.deleteResource(this.type + '/' + id).then(resource => {
        this.snackBar.open(this.type + ' deleted.', 'Close', {
          duration: 5000
        })
        this.initResourceTable()
        this.getResouceCount()
        this.getResouce()
      })
    }
  }

  visibilityResource(item: any) {
    this.request
      .patchResource(this.type + '/' + item._id, { visible: !item.visible })
      .then(resource => {
        this.snackBar.open(this.type + ' visibility changed.', 'Close', {
          duration: 5000
        })
        this.initResourceTable()
        this.getResouceCount()
        this.getResouce()
      })
  }

  goLiveDialog(merchant: any) {
    let expiresAt = merchant.trial_expires_at
    const dialogRef = this.dialog.open(GoLiveComponent, {
      width: '400px',
      data: { user: merchant, expiresAt: expiresAt }
    })
  }


  openDialog(id: string, type: string, name?: string): void {

    const dialogRef = this.dialog.open(ResourceDialogComponent, {
      width: '400px',
      data: { id, type, name, itemType: this.type, canDelete: this.canDelete }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (type === 'delete') {
          this.deleteResource(id)
        } else if (type === 'delete_selected') {
          this.deleteSelected()
        }
      }
    })
  }

  removeKeys(obj: any) {
    const keys = [
      '_id',
      'product_id',
      'category_id',
      'addon_id',
      'addonGroup_id',
      'tax_id',
      'store_id',
      'device_id',
      'created_at',
      'updated_at',
      'categories_string',
      'tax_string'
    ]

    keys.forEach(key => {
      delete obj[key]
    })

    return obj
  }

  deleteSelected() {
    const promises = []
    this.selection.forEach(selected => {
      promises.push(this.request.deleteResource(this.type + '/' + selected))
    })

    Promise.all(promises).then(result => {
      this.snackBar.open(this.selection.length + ' ' + this.type + ' deleted.', 'Close', {
        duration: 5000
      })
      this.initResourceTable()
      this.getResouceCount()
      this.getResouce()
      this.selection = []
    })
  }

  itemSelected(id: string, column: string) {
    if (column === 'select' || column === 'actions') {
      return
    }

    this.sendClickItem.emit(id)
  }

  selectAllItems(event: any) {
    if (event.checked) {
      const pag = this.dataSource.paginator
      const initialIndex = pag.pageSize * pag.pageIndex
      const lastIndex = pag.pageSize * (pag.pageIndex + 1) - 1

      for (let index = initialIndex; index <= lastIndex; index++) {
        this.selection.push(this.resource[index]._id)
      }
    } else {
      this.selection = []
    }
  }

  selectItem(event: any, id: string) {
    if (event.checked) {
      this.selection.push(id)
    } else {
      const index = this.selection.indexOf(id)
      this.selection.splice(index, 1)
    }
  }

  itemsIsChecked(id: string) {
    return this.selection.indexOf(id) >= 0
  }

  shouldPerformAction(action: string, store): boolean {
    if (action === 'delete' && this.type === 'stores') {
      return store.devices.length === 0
    }

    return this.actions.indexOf(action) >= 0
  }

  downloadPdf(elem) {
    // console.log("ResourceTableComponent -> downloadPdf -> elem", elem)
    this.request.getResource('me/invoices/' + elem._id + '/pdf').then((invoice: any) => {
      console.log("ResourceTableComponent -> downloadPdf -> request", this.request)
      console.log("ResourceTableComponent -> downloadPdf -> invoice", invoice)
      const pdf = 'data:application/pdf;base64,' + invoice.pdfBase64
      const name = invoice.pdfName
      const dlnk = document.createElement('a')
      dlnk.setAttribute('download', name)
      dlnk.href = pdf
      dlnk.click()
    })
  }

  openPaymentInfoModal(elem) {
    if (this.user.billing_info.payment_saved) {
      this.request.getResource('me/metadata').then((data: any) => {
        if (data) {
          const dialogRef = this.dialog.open(PaymentInfoModalComponent, {
            width: '800px',
            height: '400px',
            data: { invoices: elem, payment_details: data[0].payment_details }
          })
        }
      })
    } else {
      const dialogRef = this.dialog.open(SavePaymentMethodComponent, {
        width: '600px',
        data: { invoices: elem }
      })
    }
  }

  prepareData() {
    this.resource.map(data => {
      const download = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        birthDate: '',
        mailingList: '',
        identifiers: '',
        lastVisit: '',
        totalVisit: '',
        totalSpend: '',
        billingAddress: '',
        billingNumber: '',
        billingCity: '',
        billingZip: '',
        billingState: '',
        billingCountry: '',
        shippingAddress: '',
        shippingNumber: '',
        shippingCity: '',
        shippingZip: '',
        shippingState: '',
        shippingCountry: ''
      }

      download.firstName = data.first_name ? data.first_name : '-'
      download.lastName = data.last_name ? data.last_name : '-'
      download.email = data.email
      const phone = data.phone ? data.phone : ' - '
      download.phone = phone
      download.birthDate = data.birth_date ? moment(data.birth_date).format('DD/MM/YYYY') : ' - '
      download.mailingList = data.mailing_list ? data.mailing_list : false
      download.identifiers =
        data.options.identifiers && data.options.identifiers.length
          ? data.options.identifiers[0].code_type +
          ' ' +
          '-' +
          ' ' +
          data.options.identifiers[0].code_value
          : ''
      download.lastVisit =
        data && data.last_visit ? moment(data.last_visit).format('DD/MM/YYYY') : ' '
      download.totalVisit = data.total_visits ? data.total_visits : ' - '
      download.totalSpend = data.total_spend ? data.total_spend : ' - '
      download.billingAddress = data.billing && data.billing.address1 ? data.billing.address1 : ' - '
      const billingNumber = data.billing && data.billing.number ? data.billing.number : ' - '
      download.billingNumber = billingNumber
      download.billingCity = data.billing && data.billing.city ? data.billing.city : ' - '
      download.billingZip = data.billing && data.billing.zip ? data.billing.zip : ' - '
      download.billingState = data.billing && data.billing.state ? data.billing.state : ' - '
      download.billingCountry = data.billing && data.billing.country ? data.billing.country : ' - '
      download.shippingAddress = data.shipping && data.shipping && data.shipping.address1 ? data.shipping.address1 : ' - '
      const shippingNumber = data.shipping && data.shipping.number ? data.shipping.number : ' - '
      download.shippingNumber = shippingNumber
      download.shippingZip = data.shipping && data.shipping.zip ? data.shipping.zip : ' - '
      download.shippingState = data.shipping && data.shipping.state ? data.shipping.state : ' - '
      download.shippingCountry = data.shipping && data.shipping.country ? data.shipping.country : ' - '

      this.downloadData.push(download)
    })

    let options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: [
        'First Name',
        'Last Name',
        'Email',
        'Phone',
        'Birth Date',
        'Permission to Contact',
        'Identifiers',
        'Last Visit',
        'Total Visits',
        'Total Spend',
        'Billing Address',
        'Address Number',
        'Billing City',
        'Billing Zip',
        'Billing State',
        'Billing Country',
        'Shipping Address',
        'Address Number',
        'Shipping City',
        'Shipping Zip',
        'Shipping State',
        'Shipping Country'
      ]
    }
    new AngularCsv(this.downloadData, 'Customers', options)

    this.downloadData = []
  }


}
