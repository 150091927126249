import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { MediaMatcher } from '@angular/cdk/layout'
import { MenuItems } from '../../../shared/menu-items/menu-items'
import { AuthService } from '../../../services/auth.service'
import { User } from './../../../model/user.model'
import { Router } from '@angular/router'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./../full.component.scss']
})
export class AppSidebarComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList
  private _mobileQueryListener: () => void

  private userSubscription: any
  private username: string
  private user: any
  private hasStock: boolean
  private accountLogo: string
  public isAltUser: boolean 
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService,
    private router: Router,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)')
    this._mobileQueryListener = () => changeDetectorRef.detectChanges()
    this.mobileQuery.addListener(this._mobileQueryListener)
    this.hasStock = true
    this.accountLogo = ''
    this.isAltUser = false
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.getUserEvent().subscribe(user => {
      if(user.__t === 'Altuser') {
        this.isAltUser = true
      }
      this.user = user
      this.username = user ? user.email.split('@')[0] : ''
      this.accountLogo = user.contact.image ? user.contact.image : ''
    })
    this.gettingUser()
  }

  gettingUser() {
    // TODO: Find a way to not send to requests to /me
    // Login component is already calling authService.checkLogin
    this.authService.checkLogin().then(
      auth => {
        if (auth) {
          this.user = this.authService.getUser()
          setTimeout(() => {
            // this.userCanEditStock()
            if (
              (this.user.trial_expires_at === null) ||
              (this.user.trial_expires_at !== null &&
                new Date(this.user.trial_expires_at).getTime() <
                  new Date().getTime())
            ) {
              this.hasStock = false
            } else if (
              this.user.trial_expires_at !== null &&
              new Date(this.user.trial_expires_at).getTime() >
                new Date().getTime()
            ) {
              this.hasStock = true
            }
          }, 3000)
        }
      },
      error => {
        console.log(error)
      }
    )
  }

  userAlt() {
    if (this.authService.getUser().altuser) {
      this.username = this.authService.getUser().altuser.email.split('@')[0]
      return true
    } else {
      return false
    }
  }

  userReporter() {
    if (this.authService.getUser().reporter) {
      this.username = this.authService.getUser().reporter.email.split('@')[0]
      return true
    } else {
      return false
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener)
  }

  settings() {
    this.router.navigate(['settings'])
  }

  signout() {
    this.authService.signout().then(logout => {
      this.router.navigate(['auth/login']).then(result => {
        window.location.reload()
      })
    })
  }

  tracking(item) {
    window.analytics &&
      window.analytics.track(
        'dashboard:nav_sidebar:clicked:button:' + item.toLowerCase()
      )
  }

  getAccountLogo(user) {
    if (user.receipt && user.receipt.header) {
      const img = user.con.header.find(header => header.type === 'image')

      if (img && img.content) {
        return img.content
      }
    }

    return ''
  }
}
